import { type LocationQueryRaw } from "#vue-router";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const fallbackImage = "/images/placeholder.png";

export const formatDateTime = (date: string) => {
  return dayjs(date).format("MMMM D, YYYY @ h:mm A");
};

export const formatDate = (date: string) => {
  return dayjs(date).format("MMMM D, YYYY");
};

export const formatTime = (date: string) => {
  return dayjs(date).format("h:mm A");
};

// Take a number in seconds and format it as hh?:mm:ss.
// Hours is optional, but minutes and seconds will
// always be present.
export const formatTimeCode = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return [hours, minutes, secs]
    .map(v => v.toString().padStart(2, "0"))
    .filter((v, i) => i > 0 || v !== "00")
    .join(":");
}

export const timeAgoInWords = (date: string) => {
  return dayjs().to(dayjs(date));
};

export const secondsToTimecode = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return [hours, minutes, secs]
    .map(v => v.toString().padStart(2, "0"))
    .join(":");
}

export const birthdateToYears = (birthdate: string) => {
  return dayjs().diff(dayjs(birthdate), "year");
};

export const geoToLocation = (geo: string) => {
  const json = JSON.parse(geo);
  return [json.city, json.country].filter(Boolean).join(", ");
};

export const formatMoney = (amount: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
    .format(amount / 100);
};

// this amount includes 1/2 cent precision
export const formatTokenMoney = (amountWithHalfCents: number, prefix = "$") => {
  const amount = amountWithHalfCents / 1000.0;
  let dollar = amount.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (dollar.substring(dollar.length - 1) === "0") {
    dollar = dollar.slice(0, dollar.length - 1)
  }
  return `${prefix}${dollar}`;
};

export const buildQueryString = (params: Record<string, string | number | undefined>) => {
  const search = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value) search.set(key, value.toString());
  }
  return search.toString();
}

export const formatString = function(s: string, ...args: string[]) {
  for( var arg in args ) {
      s = s.replace("{" + arg + "}", args[arg]);
  }
  return s;
};

export const updateURLFromFilters = (params: Record<string, string | number | boolean | undefined>) => {
  const router = useRouter();
  router.push({ query: params as LocationQueryRaw });
}

export const getFiltersFromURL = () => {
  const router = useRouter();
  const search = new URLSearchParams(router.currentRoute.value.query as Record<string, string>);
  const filters: Record<string, string | undefined> = {};
  for (const [key, value] of search.entries()) {
    filters[key] = value;
  }
  return filters;
}

export function paginatedResultFields<T>(defaultLimit = 25) {
  return {
    page: ref(1),
    limit: ref(defaultLimit),
    loading: ref(false),
    result: ref<T | null>(null),
    hasMore: ref(false),
    totalCount: ref(0),
    totalPages: ref(0),
  }
}

export function jsonToCsv(json: any) {
  const fields = Object.keys(json[0]);
  const replacer = function(key: any, value: any) { return value === null ? '' : value }
  let csv = json.map(function(row: any) {
    return fields.map(function(fieldName) {
      return JSON.stringify(row[fieldName], replacer)
    }).join(',')
  })
  csv.unshift(fields.join(',')) // add header column
  return csv.join('\r\n');
}

export function truncate(string: string, maxNumberOfCharacters: number = 10) : string {
  if (string === undefined) {
    return "";
  } else {
    if (maxNumberOfCharacters < string.length) {
      return string.substring(0, maxNumberOfCharacters) + "...";
    } else {
      return string;
    }
  }
}
